import React, { useRef, useState } from "react";
import {
  FaVolumeMute,
  FaVolumeUp,
  FaUser,
  FaClipboard,
  FaMoneyBillAlt,
  FaQuestionCircle,
  FaBars,
  FaTimes,
  FaChalkboardTeacher,
} from "react-icons/fa";
import video from "./video.mp4";
import Topbar from "../Topbar/Topbar";
import styled from "styled-components";
import "./Hero.css";

const Section = styled.div`
  height: 90vh;
`;

function Hero() {
  const videoRef = useRef(null);
  const [isMuted, setIsMuted] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  function handleMuteToggle() {
    setIsMuted((prevMuted) => {
      const newMuted = !prevMuted;
      if (videoRef.current) {
        videoRef.current.muted = newMuted;
      }
      return newMuted;
    });
  }

  function handleMenuToggle() {
    setIsMenuOpen(!isMenuOpen);
  }

  return (
    <div>
      <Section>
        <div className="hero">
          <video
            ref={videoRef}
            className="background-video"
            autoPlay
            loop
            muted={isMuted}
            playsInline
          >
            <source src={video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>

          <div className="hero-text">
            <h1>El mundo es tuyo...</h1>
            <h2>¡Aprende idiomas!</h2>
            <div className="text">
              <p>
              Cambridge Academy of Languages, institución legalmente constituida y certificada en alta calidad a nivel nacional por International Certification and Training (IC&T) y a nivel internacional por Oxford Quality. Con más de 20 años de experiencia, nos especializamos en la enseñanza de idiomas con los más altos estándares de calidad.
              </p>
            </div>
          </div>

          <button className="mute-button" onClick={handleMuteToggle}>
            {isMuted ? <FaVolumeMute /> : <FaVolumeUp />}
          </button>

          <div className={`floating-menu ${isMenuOpen ? "open" : ""}`}>
            <button className="menu-toggle" onClick={handleMenuToggle}>
              {isMenuOpen ? <FaTimes /> : <FaBars />}
            </button>
            <a
              href="https://www.cambridgeacademy.edu.co/SGAA/"
              className="menu-item"
            >
              <FaUser /> <span>PLATAFORMA VIRTUAL NOTAS</span>
            </a>
            <a
              href="https://site2.q10.com/login?ReturnUrl=%2F"
              className="menu-item"
            >
              <FaChalkboardTeacher /> <span>CLASES VIRTUALES</span>
            </a>
            <a
              href="https://site3.q10.com/preinscripcion?aplentId=ca7edead-fa25-4d9d-8a1a-9f0ea97f1cde"
              className="menu-item"
            >
              <FaClipboard /> <span>INSCRIPCIÓN</span>
            </a>
            <a
              href="https://www.zonapagos.com/t_Cambridgeacademy/"
              className="menu-item"
            >
              <FaMoneyBillAlt /> <span>PAGOS EN LÍNEA</span>
            </a>
            <a
              href="https://www.cambridgeacademy.edu.co/Docs/pagos_virtuales.pdf"
              className="menu-item"
            >
              <FaQuestionCircle /> <span>Cómo Pagar</span>
            </a>
          </div>
        </div>
      </Section>
      <div className="topbarSection">
        <Topbar />
      </div>
    </div>
  );
}

export default Hero;