import React, { useState } from 'react';
import './Sedes.css';
import imagen1 from './Sedes.img/sede-principal.png';
import imagen2 from './Sedes.img/sede-kids.png';
import imagen3 from './Sedes.img/sede-ipiales.png';
import imagen4 from './Sedes.img/sede-ParqueBolivar.png';
import Footer from '../Footer/Footer.jsx';
import styled from 'styled-components';

const FooterContainer = styled.div`
  width: 100%;
  @media screen and (max-width: 700px) {
    width: 140%;
  }
`;

const locations = [
  {
    image: imagen1,
    title: 'CAMBRIDGE SEDE PRINCIPAL',
    description: 'Nuestras instalaciones en Cambridge Academy of Languages están diseñadas para ofrecer la mejor experiencia de aprendizaje. Contamos con 22 aulas modernas y completamente equipadas para garantizar un ambiente cómodo y efectivo para nuestros estudiantes. Además, nuestra academia se encuentra en una ubicación privilegiada, de fácil acceso y rodeada de un entorno inspirador. ¡Ven y descubre el lugar ideal para dominar un nuevo idioma!',
    address: 'Carrera 35 N° 19 - 102 Versalles, Pasto',
    phone: '317 3169220',
    moreInfo: [
      '• 22 aulas completamente equipadas',
      '• Ubicación privilegiada y de fácil acceso',
      '• Ambiente inspirador para el aprendizaje',
      '• Horario extendido de atención',
      '• Sala de profesores y áreas comunes amplias'
    ]
  },
  {
    image: imagen2,
    title: 'CAMBRIDGE KIDS',
    description: 'Nuestras instalaciones en la sede Kids están diseñadas para brindar una experiencia de aprendizaje única y envolvente. Contamos con un ambiente lúdico y divertido, ideal para que nuestros kids disfruten mientras adquieren nuevos idiomas. Además, disponemos de laboratorios equipados con la última tecnología para la aplicación de exámenes internacionales, garantizando una preparación de alto nivel. Nuestra sede está especializada en la enseñanza para niños, pero también ofrece programas para jóvenes y adultos, asegurando un aprendizaje efectivo para todas las edades.',
    address: 'Carrera 34A N° 20 - 88, Pasto',
    phone: '6027230894 - 3185774865',
    moreInfo: [
      '• Ambiente lúdico y divertido',
      '• Laboratorios con tecnología de punta',
      '• Programas para niños, jóvenes y adultos',
      '• Preparación para exámenes internacionales',
      '• Métodos de enseñanza innovadores'
    ]
  },
  {
    image: imagen3,
    title: 'CAMBRIDGE IPIALES',
    description: 'Las instalaciones de Cambridge Academy of English en la ciudad de Ipiales están diseñadas para ofrecer una experiencia de aprendizaje de primer nivel. Contamos con una infraestructura moderna y un equipo altamente capacitado para brindar cursos tanto a niños como a adultos. Aquí, cada estudiante encuentra el ambiente ideal para desarrollar sus habilidades en el idioma de manera efectiva y dinámica. ¡Te invitamos a ser parte de nuestra comunidad y vivir la mejor experiencia en el aprendizaje de idiomas!',
    address: 'Carrera 8 # 24A - 64 Barrio San Fernando, Ipiales',
    phone: '3175806885',
    moreInfo: [
      '• Cursos para niños y adultos',
      '• Infraestructura moderna y adecuada',
      '• Equipo docente altamente capacitado',
      '• Ambiente dinámico y efectivo',
      '• Comunidad de aprendizaje activa'
    ]
  },
  {
    image: imagen4,
    title: 'CAMBRIDGE PARQUE BOLIVAR',
    description: 'Las instalaciones de Cambridge Academy of Languages en la ciudad de Pasto están diseñadas para ofrecer una educación de calidad a niños y adultos. Contamos con una infraestructura moderna y un equipo altamente calificado que garantiza una experiencia de aprendizaje efectiva y dinámica. Nuestra sede Bolívar es la más nueva hasta el momento, destacándose por su diseño innovador, tecnología de punta y los equipos más actualizados. ¡Ven y descubre el mejor lugar para aprender idiomas en un ambiente moderno y motivador!',
    address: 'Calle 21 # 12 - 45, Pasto',
    phone: '3170495555',
    moreInfo: [
      '• La sede más nueva de nuestra red',
      '• Diseño innovador y tecnología de punta',
      '• Equipos actualizados y modernos',
      '• Programas para todas las edades',
      '• Ambiente motivador para el aprendizaje'
    ]
  }
];

const Sedes = () => {
  const [expandedCards, setExpandedCards] = useState([]);
  const [animating, setAnimating] = useState(null);

  const toggleDescription = (index) => {
    if (expandedCards.includes(index)) {
      setAnimating(index);
      setTimeout(() => {
        setExpandedCards(expandedCards.filter(i => i !== index));
        setAnimating(null);
      }, 300);
    } else {
      setExpandedCards([...expandedCards, index]);
    }
  };

  return (
    <div className="page-container">
      <div className="sedes-container">
        {locations.map((location, index) => (
          <div 
            key={index} 
            className={`sede ${expandedCards.includes(index) ? 'show-description' : ''}`}
          >
            <h3 className="tittleF">{location.title}</h3>
            <img 
              src={location.image} 
              alt={`Imagen de ${location.title}`} 
              className="sede-image" 
            />
            <p className="address"><strong>Dirección:</strong> {location.address}</p>
            <p className="phone"><strong>Teléfonos:</strong> {location.phone}</p>
            
            <button 
              className="more-info" 
              onClick={() => toggleDescription(index)}
              disabled={animating === index}
            >
              {expandedCards.includes(index) ? "Mostrar menos" : "Más información"}
            </button>

            <div className={`sede-description ${expandedCards.includes(index) ? 'visible' : ''}`}>
              <p className="textF">{location.description}</p>
              {expandedCards.includes(index) && (
                <div className="additional-info">
                  {location.moreInfo.map((info, i) => (
                    <p 
                      key={i} 
                      className="info-item"
                      style={{ '--i': i }}
                    >
                      {info}
                    </p>
                  ))}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>

      <FooterContainer>
        <Footer />
      </FooterContainer>
    </div>
  );
};

export default Sedes;